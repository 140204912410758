import ServiceItem from "./service";

const services = [
  {
    icon: "fas fa-cogs",
    title: "Unity Game Development",
    description:
      "Expertly crafting immersive mobile games using Unity’s powerful engine for stunning visuals and seamless gameplay.",
  },
  {
    icon: "fas fa-film",
    title: "Unreal Engine Creations",
    description:
      "Harnessing the potential of Unreal Engine to build high-quality, feature-rich mobile games with cinematic experiences.",
  },
  {
    icon: "fas fa-mobile-alt",
    title: "Mobile Innovation",
    description:
      "Pioneering creative mobile game solutions with cutting-edge technologies, pushing the boundaries of player engagement.",
  },
];

const Services = () => {
  return (
    <section id="services" className="services-area what-story pb-90 fix">
      <div className="container">
        <div className="row">
          {services.map((service, index) => {
            return (
              <ServiceItem
                key={index}
                icon={service.icon}
                title={service.title}
                description={service.description}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Services;
