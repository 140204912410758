import React, { useState } from "react";

import bgImg from "../../../assets/img/bg/trendiang-bg.png";
import SectionTitle from "../SectionTitle/SectionTitle";
import LiveStreamingVideo from "../LiveStreamingVideo/LiveStreamingVideo";

import snooker from "../../../assets/img/games/snooker.jpg";
import CarStunt from "../../../assets/img/games/car_stunt.jpeg";
import BikeStunt from "../../../assets/img/games/bike_stunt.webp";
import BusSim from "../../../assets/img/games/unnamed (2).webp";
import workImg2 from "../../../assets/img/gallery/protfolio-img02.png";
import workImg3 from "../../../assets/img/gallery/protfolio-img03.png";
import workImg4 from "../../../assets/img/gallery/protfolio-img04.png";
import androidIcon from "../../../assets/img/icons/playstore.png";
import iosIcon from "../../../assets/img/icons/icons.png";

const galleryItems = [
  {
    id: "1",
    img: snooker,
    tag: "Android",
    label: "Snooker Legends 3D",
    description: "Duis aute irure dolor i",
    cat: "Android",
    androidLink:
      "https://play.google.com/store/apps/details?id=com.snooker.innitiant.ultimatesnooker3dmaster",
    iosLink: "https://apps.apple.com/us/app/your_ios_app_id1",
  },
  {
    id: "2",
    img: CarStunt,
    tag: "New",
    label: "Extreme Stunt Car Rush",
    description: "Duis aute irure dolor i",
    cat: "Playstation",
    androidLink:
      "https://play.google.com/store/apps/details?id=your_android_app_id2",
    iosLink: "https://apps.apple.com/us/app/your_ios_app_id2",
  },
  {
    id: "3",
    img: BikeStunt,
    tag: "New",
    label: "Ultimate Bike- Racing Extreme",
    description: "Duis aute irure dolor i",
    cat: "Uplay",
    androidLink:
      "https://play.google.com/store/apps/details?id=your_android_app_id3",
    iosLink: "https://apps.apple.com/us/app/your_ios_app_id3",
  },

  {
    id: "4",
    img: BusSim,
    tag: "New",
    label: "Ultimate Bike- Racing Extreme",
    description: "Duis aute irure dolor i",
    cat: "Uplay",
    androidLink:
      "https://play.google.com/store/apps/details?id=com.vt.busgames.driving.coachbus3dgame",
    iosLink: "https://apps.apple.com/us/app/your_ios_app_id3",
  },
];

const WorkGallery = () => {
  const [items, setItems] = useState(galleryItems);

  const filterItem = (cat) => {
    const filterUpdate = galleryItems.filter((currentItem) => {
      return currentItem.cat === cat;
    });
    setItems(filterUpdate);
  };

  return (
    <section
      id="work"
      className="pt-120 pb-120"
      style={{ background: `url(${bgImg}) no-repeat` }}
    >
      <div className="container">
        <div className="portfolio">
          <div
            className="row align-items-center mb-30 wow fadeInUp animated"
            data-animation="fadeInRight"
            data-delay=".4s"
          >
            <div className="col-lg-12">
              <SectionTitle titlefirst="Trending" titleSec="Games" />
            </div>
          </div>
          <div className="grid col4">
            {galleryItems.map((item) => {
              const { id, img, label, androidLink, iosLink } = item;
              return (
                <div className="grid-item" key={id}>
                  <figure className="gallery-image">
                    <img src={img} alt={label} className="img" />
                    <figcaption>
                      <div className="custom-icons">
                        <div className="icon-group">
                          {androidLink && (
                            <a
                              href={androidLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={androidIcon}
                                alt="Android Icon"
                                className="app-icon"
                              />
                            </a>
                          )}
                          {iosLink && (
                            <a
                              href={iosLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={iosIcon}
                                alt="iOS Icon"
                                className="app-icon"
                              />
                            </a>
                          )}
                        </div>
                      </div>
                      <h4>{label}</h4>
                    </figcaption>
                  </figure>
                </div>
              );
            })}
          </div>
        </div>
        {/* <LiveStreamingVideo /> */}
      </div>
    </section>
  );
};

export default WorkGallery;
