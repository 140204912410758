import { Link } from "react-router-dom";
import aboutBgImg from "../../../assets/img/bg/about-bg.png";
import experienceImg from "../../../assets/img/features/round_text2.png";
import features1 from "../../../assets/img/features/toon1.PNG";
import features2 from "../../../assets/img/features/about-img2.jpg";
import features3 from "../../../assets/img/features/about-img3.png";
import SectionTitle from "../SectionTitle/SectionTitle";

const About = () => {
  return (
    <section
      id="about"
      className="about-area about-p pt-70 pb-140 p-relative"
      style={{
        background: `url(${aboutBgImg}) no-repeat center center / cover`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div
              className="s-about-img p-relative wow fadeInLeft"
              data-wow-delay=".4s"
            >
              <div
                className="experience-years wow fadeInDown"
                data-wow-delay=".4s"
              >
                <img src={experienceImg} alt="Experience Years" />
                <span>{"V"}</span>
              </div>
              <img src={features1} alt="About Image" />
              {/* <div className="about-image2 wow fadeInUp" data-wow-delay=".4s">
                <img src={features2} alt="About Image" />
              </div> */}
              <div className="about-image3 wow fadeInUp" data-wow-delay=".6s">
                <img src={features3} alt="About Image" />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div
              className="about-content s-about-content wow fadeInRight"
              data-wow-delay=".4s"
            >
              <SectionTitle
                titlefirst={"Fueling Your "}
                titleSec={"Mobile Adventure"}
                className={"about-title second-title"}
              />
              <p>
                <b>
                  At Vulcantech Game Studio, we ignite imagination and bring
                  dynamic mobile gaming experiences to life. Our passion for
                  innovation and storytelling drives us to create games that
                  captivate players across the globe.
                </b>
              </p>
              <p>
                We believe that every tap, swipe, and interaction should lead to
                adventure. Our dedicated team of developers, designers, and
                artists work tirelessly to craft mobile games that are visually
                stunning, immersive, and engaging. Whether you're looking for a
                casual escape or a challenging quest, Vulcantech has it all.
              </p>
              <div className="about-content3 mt-30">
                <div className="row">
                  <div className="col-md-12">
                    <ul className="green">
                      <li>
                        {"Innovative game design that pushes boundaries."}
                      </li>
                      <li>
                        {"Visually immersive and intuitive mobile experiences."}
                      </li>
                      <li>
                        {
                          "Passion-driven creativity with cutting-edge technology."
                        }
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
