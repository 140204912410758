import { Link } from "react-router-dom";
import heroImg from "../../../../assets/img/slider/slider_img_bg.png";
import heroImg2 from "../../../../assets/img/slider/slider_img01.png";
import heroImg3 from "../../../../assets/img/slider/slider_img02.png";
import heroImg4 from "../../../../assets/img/slider/11.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules"; // Import from 'swiper/modules' for newer versions
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const heroInformation = [
  {
    id: "1",
    img: heroImg4,
    titleTag: "#World class game",
    title: "Are You ready For your next Challenge?",
    btnText: "Read More",
  },
  {
    id: "2",
    img: heroImg,
    titleTag: "#Epic Adventure",
    title: "Unleash the Hero in You",
    btnText: "Start Now",
  },
  {
    id: "3",
    img: heroImg2,
    titleTag: "#Ultimate Experience",
    title: "Step into the Future of Gaming",
    btnText: "Join Us",
  },
];

const HeroOne = () => {
  return (
    <section id="home" className="slider-area slider-four fix p-relative">
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        pagination={{ clickable: true }}
        navigation={true} // Enable navigation
        modules={[Navigation, Pagination]} // Add the modules
      >
        {heroInformation.map(({ id, img, titleTag, title, btnText }) => (
          <SwiperSlide key={id}>
            <div
              className="single-slider slider-bg d-flex align-items-center"
              style={{
                background: `url(${img}) no-repeat center center / cover`,
              }}
            >
              <div className="container">
                <div className="row justify-content-center pt-50">
                  <div className="col-lg-1 col-md-1"></div>
                  <div className="col-lg-6 col-md-6">
                    <div className="slider-content s-slider-content">
                      {/* <h5 data-animation="fadeInDown" data-delay=".4s">
                        {titleTag}
                      </h5>
                      <h2 data-animation="fadeInUp" data-delay=".4s">
                        {title}
                      </h2> */}
                      <div className="slider-btn">
                        {/* <Link to={"/about"} className="btn ss-btn mr-15">
                          {btnText}
                        </Link> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-5"></div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Custom CSS for the Swiper arrows */}
      <style>{`
        .swiper-button-next, .swiper-button-prev {
          color: white; /* Tomato color */
        }
        .swiper-button-next:hover, .swiper-button-prev:hover {
          color: white; /* Light Salmon on hover */
        }
      `}</style>
    </section>
  );
};

export default HeroOne;
